<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Users</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
      <div class="flex items-center"></div>
      <router-link
        to="/agents/add"
        class="border px-6 py-2 rounded-lg text-sm flex justify-center items-center hover:bg-gray-100"
      >
        New User
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div>
      <div
        v-if="isLoading"
        class="w-screen h-screen flex justify-center items-center"
      >
        <p class="text-blue-700 font-bold text-center">loading...</p>
      </div>

      <div v-else class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <!-- <div
              v-if="onFilter"
              class="flex grid-flow-col gap-4 bg-gray-50 p-3"
            >
              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by staff
                </label>
                <select
                  class="p-2 border rounded"
                  name="branch"
                  @change="loadData(1, filter)"
                  v-model="filterStaff"
                >
                  <option value="" disabled selected>Filter by staff</option>
                  <option
                    v-for="(staff, i) in staffs"
                    :key="i"
                    :value="staff.id"
                  >
                    {{ staff.name }}
                  </option>
                </select>
              </div>

              <div class="flex flex-col w-80">
                <label class="text-gray-600" for="branch"
                  >Filter by date
                </label>
                <input
                  class="p-2 border rounded text-xs"
                  type="date"
                  name="date"
                  @change="loadData(1, filter)"
                  v-model="filter"
                />
              </div>
            </div> -->
              <table
                class="min-w-full divide-y divide-gray-200"
                v-if="users.length > 0"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Branch
                    </th>

                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>

                    <th scope="col" class="relative px-6 py-3">
                      <span class="text-gray-500">Actions</span>
                    </th>
                  </tr>
                </thead>
                <!-- <div v-if="agents.length > 0"> -->
                <tbody
                  class="bg-white divide-y divide-gray-200"
                  v-for="(user, i) in users"
                  :key="i"
                >
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div
                            class="h-10 w-10 rounded-full bg-gray-200 flex justify-center items-center"
                          >
                            <!-- {{ user.entity_name }} test User -->
                          </div>
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">
                            {{ user.name }}...
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div class="flex justify-center items-center">
                            {{ user.phone }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div class="flex justify-center items-center">
                            {{ user.branch ? user.branch : "Not Specified" }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <div
                            class="h-10 w-20 rounded-full bg-gray-100 flex justify-center items-center"
                          >
                            <span
                              class="text-blue-500 text-sm font-bold"
                              v-if="user.status"
                              >Active</span
                            >
                            <span v-else class="text-red-500 text-sm font-bold"
                              >In-active</span
                            >
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <div class="flex justify-end">
                        <button
                          @click="onDropDown(i)"
                          class="relative z-10 block rounded-md bg-white text-xl p-2 focus:outline-none"
                        >
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </button>
                      </div>

                      <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                      >
                        <div
                          v-show="
                            dropdownOpen.isOpen && dropdownOpen.index == i
                          "
                          @click="onDropDown(i)"
                          class="absolute right-10 mt-2 py-2 w-48 bg-white rounded-md shadow-xl z-20"
                        >
                          <button
                            @click="setAgentStatus(user.id, user.status)"
                            class="block px-4 py-2 text-sm capitalize w-full text-gray-700 hover:bg-blue-500 hover:text-white"
                          >
                            <span
                              class="text-gray-700 hover:text-white"
                              v-if="user.status"
                            >
                              Deactivate</span
                            >
                            <span class="text-gray-700 hover:text-white" v-else>
                              Activate
                            </span>
                          </button>
                          <button
                            @click="editAgent(user.id)"
                            class="block px-4 py-2 text-sm w-full capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
                          >
                            Edit
                          </button>
                          <button
                            @click="resetPassword(user.id)"
                            class="block px-4 py-2 text-sm capitalize w-full text-gray-700 hover:bg-red-500 hover:text-white"
                          >
                            Reset password
                          </button>
                        </div>
                      </transition>
                    </td>
                  </tr>

                  <!-- More people... -->
                </tbody>
                <!-- </div> -->
              </table>

              <div class="border-t">
                <!-- <pagination :data="agents" :midSize="2" @clicked="loadData"> </pagination> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import Pagination from "../../components/Pagination";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";
import Swal from "sweetalert2";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  // components: { Pagination },

  layout: "default",

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  data: () => ({
    filter: "",
    onFilter: false,
    isLoading: "",

    filterAgents: "",
    filterDate: "",
    searchStaffs: [],
    users: [],
    searching: false,
    selectedAgent: null,
    isConfirmModalVisible: false,
    dropdownOpen: {
      isOpen: false,
      index: null,
    },
    userHasConfirmed: false,
    modalType: "",
  }),

  // mounted() {

  // },
  mounted() {
    this.loadData(1, this.filter);

    if (this.user.data.type !== "admin") {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    async resetPassword(id) {
      // Submit the form
      this.isLoading = true;

      try {
        console.log("id..", id);

        await axios.post(`/agent/user/reset/`, {
          user_id: id,
        });
        this.isLoading = false;
        if (!this.isLoading) {
          Swal.fire({
            icon: "success",
            title: "Password Successfully Rest",
          });
          // this.$router.push({ path: "/staffs" });

          return true;
        }
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
    editAgent(agentId) {
      console.log("agentId", agentId);

      this.$router.push({ name: "editAgent", query: { id: agentId } });
    },

    async setAgentStatus(id, status) {
      this.isLoading = true;

      try {
        await axios.patch(`/agent/user/update/status?user_id=${id}`);

        this.isLoading = false;
        const state = status ? "deactivated" : "activated";

        this.loadData(1);
        Swal.fire({
          icon: "success",
          title: "Status",
          text: `User ${state} successfully`,
        });
        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
    search(e) {
      console.log("e", e.target.value);

      this.searching = true;
      this.loadData(1, e.target.value);
    },

    async loadData(pageNo, filter) {
      if (filter) {
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(`/agent/users`);

        this.users = data;
        this.searching = false;
        return true;
      } catch (e) {
        console.log("Error", e);
        this.searching = false;
        return false;
      }
    },
    onDropDown(i) {
      console.log("i ", i);
      if (this.dropdownOpen.isOpen == false) {
        this.dropdownOpen.isOpen = true;
        this.dropdownOpen.index = i;

        return;
      }

      this.dropdownOpen.isOpen = false;
      this.dropdownOpen.index = null;
    },

    showConfirmModal(agent, type) {
      this.selectedAgent = agent;
      this.modalType = type;
      this.isConfirmModalVisible = true;
    },
    closeConfirmModal() {
      this.selectedAgent = null;
      this.isConfirmModalVisible = false;
    },

    confirmRequest(req) {
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        if (this.modalType === "deactivate") {
          this.updateStatus();
        }

        if (this.modalType === "password") {
          this.resetPassword();
        }
      }
    },
  },
};
</script>
